/*****************
	Utility
 *****************/
/*
  breakpoint
*/
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;


/*
  Font Size Utility
*/
$step: 1;
@for $i from 0 through 100 {
	.fs-#{$i * $step} {
		font-size: #{$i * $step}px !important;
	}
}
@media(min-width: 768px) {
	@for $i from 0 through 100 {
		.fs-sm-#{$i * $step} {
			font-size: #{$i * $step}px !important;
		}
	}
}
@media(min-width: #{$md}) {
	@for $i from 0 through 100 {
		.fs-md-#{$i * $step} {
			font-size: #{$i * $step}px !important;
		}
	}
}
@media(min-width: #{$lg}) {
	@for $i from 0 through 100 {
		.fs-lg-#{$i * $step} {
			font-size: #{$i * $step}px !important;
		}
	}
}

/*
  Spacing Utility
  40までは1刻み,それ以上は5刻み
*/
$limit: 40;
$limit-lg: 100;

$step: 1;
@for $i from 0 through $limit {
	.m-#{$i * $step} {
		margin: #{$i * $step}px !important;
	}
	.mx-#{$i * $step} {
		margin-left: #{$i * $step}px !important;
		margin-right: #{$i * $step}px !important;
	}
	.my-#{$i * $step} {
		margin-top: #{$i * $step}px !important;
		margin-bottom: #{$i * $step}px !important;
	}
	.mt-#{$i * $step} {
		margin-top: #{$i * $step}px !important;
	}
	.mr-#{$i * $step} {
		margin-right: #{$i * $step}px !important;
	}
	.mb-#{$i * $step} {
		margin-bottom: #{$i * $step}px !important;
	}
	.ml-#{$i * $step} {
		margin-left: #{$i * $step}px !important;
	}
	.p-#{$i * $step} {
		padding: #{$i * $step}px !important;
	}
	.px-#{$i * $step} {
		padding-left: #{$i * $step}px !important;
		padding-right: #{$i * $step}px !important;
	}
	.py-#{$i * $step} {
		padding-top: #{$i * $step}px !important;
		padding-bottom: #{$i * $step}px !important;
	}
	.pt-#{$i * $step} {
		padding-top: #{$i * $step}px !important;
	}
	.pr-#{$i * $step} {
		padding-right: #{$i * $step}px !important;
	}
	.pb-#{$i * $step} {
		padding-bottom: #{$i * $step}px !important;
	}
	.pl-#{$i * $step} {
		padding-left: #{$i * $step}px !important;
	}
}
$step: 5;
@for $i from 5 through $limit-lg {
	.m-#{$i * $step} {
		margin: #{$i * $step}px !important;
	}
	.mx-#{$i * $step} {
		margin-left: #{$i * $step}px !important;
		margin-right: #{$i * $step}px !important;
	}
	.my-#{$i * $step} {
		margin-top: #{$i * $step}px !important;
		margin-bottom: #{$i * $step}px !important;
	}
	.mt-#{$i * $step} {
		margin-top: #{$i * $step}px !important;
	}
	.mr-#{$i * $step} {
		margin-right: #{$i * $step}px !important;
	}
	.mb-#{$i * $step} {
		margin-bottom: #{$i * $step}px !important;
	}
	.ml-#{$i * $step} {
		margin-left: #{$i * $step}px !important;
	}
	.p-#{$i * $step} {
		padding: #{$i * $step}px !important;
	}
	.px-#{$i * $step} {
		padding-left: #{$i * $step}px !important;
		padding-right: #{$i * $step}px !important;
	}
	.py-#{$i * $step} {
		padding-top: #{$i * $step}px !important;
		padding-bottom: #{$i * $step}px !important;
	}
	.pt-#{$i * $step} {
		padding-top: #{$i * $step}px !important;
	}
	.pr-#{$i * $step} {
		padding-right: #{$i * $step}px !important;
	}
	.pb-#{$i * $step} {
		padding-bottom: #{$i * $step}px !important;
	}
	.pl-#{$i * $step} {
		padding-left: #{$i * $step}px !important;
	}
}

@media(min-width: #{$sm}) {
	$step: 1;
	@for $i from 0 through $limit {
		.m-sm-#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.mx-sm-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
			margin-right: #{$i * $step}px !important;
		}
		.my-sm-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
			margin-bottom: #{$i * $step}px !important;
		}
		.mt-sm-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.mr-sm-#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.mb-sm-#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.ml-sm-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.p-sm-#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.px-sm-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
			padding-right: #{$i * $step}px !important;
		}
		.py-sm-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
			padding-bottom: #{$i * $step}px !important;
		}
		.pt-sm-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.pr-sm-#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.pb-sm-#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.pl-sm-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
	$step: 5;
	@for $i from 5 through $limit-lg {
		.m-sm-#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.mx-sm-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
			margin-right: #{$i * $step}px !important;
		}
		.my-sm-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
			margin-bottom: #{$i * $step}px !important;
		}
		.mt-sm-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.mr-sm-#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.mb-sm-#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.ml-sm-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.p-sm-#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.px-sm-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
			padding-right: #{$i * $step}px !important;
		}
		.py-sm-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
			padding-bottom: #{$i * $step}px !important;
		}
		.pt-sm-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.pr-sm-#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.pb-sm-#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.pl-sm-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
}

@media(min-width: #{$md}) {
	$step: 1;
	@for $i from 0 through $limit {
		.m-md-#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.mx-md-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
			margin-right: #{$i * $step}px !important;
		}
		.my-md-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
			margin-bottom: #{$i * $step}px !important;
		}
		.mt-md-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.mr-md-#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.mb-md-#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.ml-md-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.p-md-#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.px-md-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
			padding-right: #{$i * $step}px !important;
		}
		.py-md-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
			padding-bottom: #{$i * $step}px !important;
		}
		.pt-md-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.pr-md-#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.pb-md-#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.pl-md-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
	$step: 5;
	@for $i from 5 through $limit-lg {
		.m-md-#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.mx-md-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
			margin-right: #{$i * $step}px !important;
		}
		.my-md-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
			margin-bottom: #{$i * $step}px !important;
		}
		.mt-md-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.mr-md-#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.mb-md-#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.ml-md-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.p-md-#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.px-md-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
			padding-right: #{$i * $step}px !important;
		}
		.py-md-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
			padding-bottom: #{$i * $step}px !important;
		}
		.pt-md-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.pr-md-#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.pb-md-#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.pl-md-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
}

@media(min-width: #{$lg}) {
	$step: 1;
	@for $i from 0 through $limit {
		.m-lg-#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.mx-lg-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
			margin-right: #{$i * $step}px !important;
		}
		.my-lg-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
			margin-bottom: #{$i * $step}px !important;
		}
		.mt-lg-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.mr-lg-#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.mb-lg-#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.ml-lg-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.p-lg-#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.px-lg-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
			padding-right: #{$i * $step}px !important;
		}
		.py-lg-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
			padding-bottom: #{$i * $step}px !important;
		}
		.pt-lg-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.pr-lg-#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.pb-lg-#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.pl-lg-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
	$step: 5;
	@for $i from 5 through $limit-lg {
		.m-lg-#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.mx-lg-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
			margin-right: #{$i * $step}px !important;
		}
		.my-lg-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
			margin-bottom: #{$i * $step}px !important;
		}
		.mt-lg-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.mr-lg-#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.mb-lg-#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.ml-lg-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.p-lg-#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.px-lg-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
			padding-right: #{$i * $step}px !important;
		}
		.py-lg-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
			padding-bottom: #{$i * $step}px !important;
		}
		.pt-lg-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.pr-lg-#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.pb-lg-#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.pl-lg-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
}

@media(min-width: #{$xl}) {
	$step: 1;
	@for $i from 0 through $limit {
		.m-xl-#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.mx-xl-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
			margin-right: #{$i * $step}px !important;
		}
		.my-xl-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
			margin-bottom: #{$i * $step}px !important;
		}
		.mt-xl-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.mr-xl-#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.mb-xl-#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.ml-xl-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.p-xl-#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.px-xl-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
			padding-right: #{$i * $step}px !important;
		}
		.py-xl-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
			padding-bottom: #{$i * $step}px !important;
		}
		.pt-xl-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.pr-xl-#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.pb-xl-#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.pl-xl-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
	$step: 5;
	@for $i from 5 through $limit-lg {
		.m-xl-#{$i * $step} {
			margin: #{$i * $step}px !important;
		}
		.mx-xl-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
			margin-right: #{$i * $step}px !important;
		}
		.my-xl-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
			margin-bottom: #{$i * $step}px !important;
		}
		.mt-xl-#{$i * $step} {
			margin-top: #{$i * $step}px !important;
		}
		.mr-xl-#{$i * $step} {
			margin-right: #{$i * $step}px !important;
		}
		.mb-xl-#{$i * $step} {
			margin-bottom: #{$i * $step}px !important;
		}
		.ml-xl-#{$i * $step} {
			margin-left: #{$i * $step}px !important;
		}
		.p-xl-#{$i * $step} {
			padding: #{$i * $step}px !important;
		}
		.px-xl-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
			padding-right: #{$i * $step}px !important;
		}
		.py-xl-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
			padding-bottom: #{$i * $step}px !important;
		}
		.pt-xl-#{$i * $step} {
			padding-top: #{$i * $step}px !important;
		}
		.pr-xl-#{$i * $step} {
			padding-right: #{$i * $step}px !important;
		}
		.pb-xl-#{$i * $step} {
			padding-bottom: #{$i * $step}px !important;
		}
		.pl-xl-#{$i * $step} {
			padding-left: #{$i * $step}px !important;
		}
	}
}

/*
  Text Utility
*/
.text-left		{ text-align: left !important; }
.text-center	{ text-align: center !important; }
.text-right		{ text-align: right !important; }
@media(min-width: 768px) {
	.text-sm-left		{ text-align: left !important; }
	.text-sm-center		{ text-align: center !important; }
	.text-sm-right		{ text-align: right !important; }
}
@media(min-width: #{$md}) {
	.text-md-left		{ text-align: left !important; }
	.text-md-center		{ text-align: center !important; }
	.text-md-right		{ text-align: right !important; }
}
@media(min-width: #{$lg}) {
	.text-lg-left		{ text-align: left !important; }
	.text-lg-center		{ text-align: center !important; }
	.text-lg-right		{ text-align: right !important; }
}
@media(min-width: #{$xl}) {
	.text-xl-left		{ text-align: left !important; }
	.text-xl-center		{ text-align: center !important; }
	.text-xl-right		{ text-align: right !important; }
}

.underline { text-decoration: underline; }
.bold {font-weight: bold;}


.bg-cover {
	background-size: cover;
	background-position: center center;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 56%;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9 {
  &::before {
    padding-top: percentage(9 / 21);
  }
}

.embed-responsive-16by9 {
  &::before {
    padding-top: percentage(9 / 16);
  }
}

.embed-responsive-4by3 {
  &::before {
    padding-top: percentage(3 / 4);
  }
}

.embed-responsive-1by1 {
  &::before {
    padding-top: percentage(1 / 1);
  }
}

.w100 {
	width: 100%;
	height: auto;
}